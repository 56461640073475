import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '../assets/scss/main.scss'

const Layout = ({ children, location }) => {

  let content;

  if (location && location.pathname === '/') {
    content = (
      <div>
        {children}
      </div>
    )
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>
          {children}
        </div>
      </div>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'DaD Digital' },
              { name: 'keywords', content: 'software engineering, software consultancy' },
                { 'name':'description',  content:'Software Engineering Partnerships'},
                { 'name':'image' , content:'https://www.daddigital.com/banner.png'},
                { 'name':'og:url' , content:'https://www.daddigital.com'},
                { 'name':'og:type' , content:'website'},
                { 'name':'og:title' , content:'DaD Digital Pty Ltd'},
                { 'name':'og:description' , content:'Sampl'},
                { 'name':'og:image' , content:'https://www.daddigital.com/banner.png'},
                { 'name':'twitter:card' , content:'summary_large_image'},
                { 'name':'twitter:title' , content:'DaD Digital Pty Ltd'},
                { 'name':'twitter:description' , content:'Sample'},
                { 'name':'twitter:image' , content:'https://www.daddigital.com/banner.png'}  
                
                
                
                
                
            ]}
          >
              
            <html lang="en" />
          </Helmet>
          {content}
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
